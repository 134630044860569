export default function faqInit() {
    const selectors = {
        main: '.b-faq',
        header: '.b-faq__header',
        textHolder: '.b-faq__text-holder',
        item: '.b-faq__item'
    };

    const classes = {
        opened: 'b-faq__item_opened'
    };

    $(`${selectors.main}.js-init`).each((index, elem) => {

        const $elem = $(elem);
        const elems = {
            $main: $elem,
            $header: $elem.find(selectors.header),
            $textHolder: $elem.find(selectors.textHolder),
            $items: $elem.find(selectors.item)
        };

        elems.$header.click((e) => {
            const $target = $(e.currentTarget).closest(elems.$items);
            elems.$items.not($target).removeClass(classes.opened);
            $target.toggleClass(classes.opened);
        })

    })
}
