import {tns} from '../../../../node_modules/tiny-slider/src/tiny-slider';
import popupsInit, {openPopup} from "../b-popup/b-popup";

const ICON_SRC = '../assets/images/cross-file.svg';
const ERROR_CODES = {
    FILE_TYPE: 100,
    FILE_SIZE: 200
};

export default class InputFiles {
    static get selectors() {
        return {
            main: '.b-input-files',
            inputElem: '.b-input-files__input-elem',
            input: '.b-input-files__input',
            filesHolder: '.b-input-files__files-holder',
            icon: '.b-input-files__files-icon',
            placeholder: '.b-input-files__placeholder'
        }
    }

    static get classes() {
        return {
            filesActive: 'b-input-files_files-active',
            deleteIcon: 'b-input-files__delete-btn',
            fileName: 'b-input-files__file-name',
            file: 'b-input-files__file',
            fileItem: 'b-input-files__file-item',
            deleteBtnHolder: 'b-input-files__delete-holder'
        }
    }

    static get fileFilter() {
        return {
            types: ['jpg','jpeg','gif','png','bmp','tif','tiff','wmf','tga','svg','zip','rar','tar','7z',
                'doc','docx','pdf','xls','xlsx','xlsm','odt','txt','rtf','djvu','ppt','pptx','accdb','mdb',
                'dwg','cdw','m3d','psd'],
            maxFileSize: '104857600', // размер в байтах
        }
    }

    static get errorPopups() {
        return {
            [ERROR_CODES.FILE_SIZE]: '#files-error-size',
            [ERROR_CODES.FILE_TYPE]: '#files-error-type'
        }
    }

    static init() {
        document.querySelectorAll(InputFiles.selectors.main).forEach(elem => {
            new InputFiles(elem);
        })
    }

    constructor(elem) {
        this.params = {
            selectors: InputFiles.selectors,
            classes: InputFiles.classes
        };

        this.elems = {
            main: elem,
            icon: elem.querySelector(`:scope ${this.params.selectors.icon}`),
            input: elem.querySelector(`:scope ${this.params.selectors.input}`),
            inputElem: elem.querySelector(`:scope ${this.params.selectors.inputElem}`),
            filesHolder: elem.querySelector(`:scope ${this.params.selectors.filesHolder}`),
        };

        this.state = {
            filesExist: false,
            fileStore: [],
            fileViewsReadyCounter: 0,
            filesAddErrorCode: null
        };

        this.handleFilesDownloadTrigger();
        this.handleFilesChoose();

        this.initBlockApi();
    }

    initBlockApi() {
        $(this.elems.main).data('block-api', this);
    }

    initFilesCarousel(isRebuild = false) {
        if(isRebuild){
            this.carousel.rebuild()
        } else {
            this.carousel = tns({
                container: this.elems.filesHolder,
                controls: false,
                autoWidth: true,
                nav: false,
                mouseDrag: true,
                gutter: 10,
                loop: false
            })
        }

    }

    handleFilesDownloadTrigger() {
        this.elems.icon.addEventListener('click', (event) => {
            this.elems.inputElem.click();
        });
        this.elems.input.addEventListener('click', event => {
            if(!this.state.filesExist){
                this.elems.inputElem.click();
            }
        })
    }

    handleFilesChoose() {
        this.elems.inputElem.addEventListener('change', event => {
           const files = this.elems.inputElem.files;
            if(files.length > 0){
                this.tryAddFiles(files)
            }
        })
    }

    clearFiledNodes() {
        this.state.fileViewsReadyCounter = 0;
        const container = this.elems.filesHolder;
        while (container.firstChild) {
            container.removeChild(container.firstChild)
        }
    }

    tryAddFiles(filesFromInput) {
        const filesArr = Object.keys(filesFromInput).map(index => filesFromInput[index]);
        const filteredFiles = this.filterFiles(filesArr);
        if(filteredFiles.length > 0 && !this.state.filesAddErrorCode){
            this.addFilesInStore(filteredFiles);
            this.renderFiles();
        } else {
            if(this.state.filesAddErrorCode){
                const $popup = $(InputFiles.errorPopups[this.state.filesAddErrorCode]);
                openPopup($popup);
            }
        }
    }

    renderFiles() {
        this.elems.inputElem.value = '';
        this.clearFiledNodes();
        this.state.fileStore.map((file, index) => {
            this.renderFile(file, index)
        });
        if (!this.state.filesExist) {
            this.state.filesExist = true;
            this.elems.main.classList.add(this.params.classes.filesActive);
        }
    }

    filterFiles(files) {
        const filtered =  files.filter(file => {
            this.state.filesAddErrorCode = null;
            const fileType = file.name.split('.').pop();
            const isTypeCorrect = InputFiles.fileFilter.types.find(type => type === fileType);
            if(!isTypeCorrect){
                this.state.filesAddErrorCode = ERROR_CODES.FILE_TYPE;
            }
            const isSizeCorrect = file.size <= InputFiles.fileFilter.maxFileSize;
            if(!isSizeCorrect){
                this.state.filesAddErrorCode = ERROR_CODES.FILE_SIZE;
            }
            return isTypeCorrect && isSizeCorrect &&
                !this.state.fileStore.find(fileInStore => fileInStore.name === file.name)
        });

        return filtered;
    }

    addFilesInStore(files) {
        this.state.fileStore = this.state.fileStore.concat(files)
    }

    removeFilesByIndex(index) {
        this.state.fileStore.splice(index, 1);
        if(this.state.fileStore.length > 0){
            this.renderFiles();
        } else {
            this.elems.main.classList.remove(this.params.classes.filesActive);
            this.state.fileViewsReadyCounter = 0;
            setTimeout(() => {
                this.state.filesExist = false;
            }, 500)
        }
    }

    renderFile(file, index) {
        const deleteBtnElem = document.createElement('img');
        deleteBtnElem.src = ICON_SRC;
        deleteBtnElem.onclick = () => {
            this.removeFilesByIndex(index);
        };
        deleteBtnElem.onload = () => {
            this.state.fileViewsReadyCounter += 1;
            if(this.state.fileViewsReadyCounter === this.state.fileStore.length &&
                this.state.fileViewsReadyCounter !== 0){
                this.initFilesCarousel();
            }
        };
        deleteBtnElem.classList.add(this.params.classes.deleteIcon);

        const deleteBtnHolder = document.createElement('div');
        deleteBtnHolder.classList.add(this.params.classes.deleteBtnHolder);
        deleteBtnHolder.appendChild(deleteBtnElem);

        const fileNameElem = document.createElement('div');
        fileNameElem.classList.add(this.params.classes.fileName);
        fileNameElem.innerHTML = file.name;

        const fileContainer = document.createElement('div');
        fileContainer.classList.add(this.params.classes.file);
        fileContainer.appendChild(fileNameElem);
        fileContainer.appendChild(deleteBtnHolder);

        const fileItemContainer = document.createElement('div');
        fileItemContainer.classList.add(this.params.classes.fileItem);
        fileItemContainer.appendChild(fileContainer);

        this.elems.filesHolder.appendChild(fileItemContainer);
    }
}