import {tns} from '../../../../node_modules/tiny-slider/src/tiny-slider';



export default function worksSliderInit() {
    const selectors = {
        main: '.b-works-slider',
        slider: '.b-works-slider__slider',
        slide: '.b-works-slider__item',
        visibleSlide: '.tns-slide-active',
        workPlate: '.b-work-plate'
    };

    const classes = {
        opacityMin: 'b-work-plate_opacity_min',
        opacityMid: 'b-work-plate_opacity_mid',
        opacityMax: 'b-work-plate_opacity_max',
    }

    const dataAttrs = {
        inlineOptions: 'data-slider-options'
    };

    document.querySelectorAll(selectors.main).forEach(elem => {
        initSlider(elem)
    });

    function switchOpacity(elems) {
        const visibleSlides = elems.main.querySelectorAll(`:scope ${selectors.visibleSlide}`)
        for (let key in classes) {
            for (let node of visibleSlides){
                node.querySelector(selectors.workPlate).classList.remove(classes[key])
            }
        }

        visibleSlides[2].querySelector(selectors.workPlate).classList.add(classes.opacityMin);
        visibleSlides[3].querySelector(selectors.workPlate).classList.add(classes.opacityMid);
        visibleSlides[4].querySelector(selectors.workPlate).classList.add(classes.opacityMax);
    }

    function initSlider(elem) {
        const elems = {
            main: elem,
            slider: elem.querySelector(selectors.slider),
        };

        const inlineSliderOptions = elems.main.getAttribute(dataAttrs.inlineOptions) || {};

        const sliderDefaults = {
            container: elems.slider,
            axis: 'vertical',
            items: 5,
            speed: 500,
            controls: false,
            autoplay: true,
            autoplayTimeout: 1500,
            autoplayButtonOutput: false,
            onInit: () => switchOpacity(elems)
        };

        const slider = tns({
            ...sliderDefaults,
            ...inlineSliderOptions
        });

        slider.events.on('transitionStart', () => switchOpacity(elems))


    }
}

