const selectors = {
    main: '.b-popup',
    closeBtn: '.js-popup-close',
    openBtn: '.js-popup-open',
    body: '.b-page',
    inner: '.b-popup__inner',
    videoBlock: '.b-video',
};
const classes = {
    popupVisible: 'b-popup_visible',
    bodyPopupVisible: 'b-page_popup-visible'
};
const dataSelectors = {
    openTarget: 'popup'
};

export function openPopup($popup){
    $popup.addClass(classes.popupVisible);
    $(selectors.body).addClass(classes.bodyPopupVisible);
}

export function closePopup($popup = $(`.${classes.popupVisible}`)){
    $popup.removeClass(classes.popupVisible);
    $(selectors.body).removeClass(classes.bodyPopupVisible);

    const settings = $popup.data('settings');
    if(settings){
        if(settings.stopVideo){
            const player = $popup.find(selectors.videoBlock).data('player-api');
            if (player) player.stopVideo();
        }
        if(settings.pauseVideo){
            const player = $popup.find(selectors.videoBlock).data('player-api');
            if (player) player.pauseVideo();
        }
    }
}

const popupsInit = () => {

    const settingsMethods = {};

    function initHandleOuterClose(){
        $(document).mouseup((e) => {

            const $div = $(`.${classes.popupVisible} ${selectors.inner}`);
            if( !$div.is(e.target)
                && $div.has(e.target).length === 0
                && !$(`.${classes.popupVisible}`).data('disable-outer-close')){
                    closePopup();
            }
        })
    }

    function applySettings($popup) {
        for(let method in $popup.data('settings')){
            if(settingsMethods.hasOwnProperty(method)){
                settingsMethods[method]($popup.data('settings')[method])
            }
        }
    }

    $(document).on('click', selectors.openBtn, (e) => {
        const $target = $(e.currentTarget);
        const targetId = $target.data(dataSelectors.openTarget);
        if (!targetId) return;

        const targetSelector = `#${targetId}`;
        openPopup($(targetSelector));
    })

    $(selectors.closeBtn).click((e) => {
        const $target = $(e.currentTarget).closest(selectors.main);
        closePopup($target);
    })

    initHandleOuterClose();

    $(selectors.main).each((index, elem) => {
        if($(elem).data('settings')){
            applySettings($(elem));
        }
    })

}

export default popupsInit;
