export default function initDatePicker() {
    const selectors = {
        main: '.b-input-date',
        input: '.b-input-date__input'
    };

    $(selectors.main).each((i, elem) => {
        init($(elem));
    });

    function init(elem) {
        const elems = {
            $main: elem,
            $input: elem.find(selectors.input)
        };

        const date = new Date();

        elems.$input.datepicker({
            autoClose: true,
            classes: elems.$main.data('datepicker-class') || '',
            dateFormat: 'dd/mm/yyyy',
        });

        const datePickerObject = elems.$input.datepicker().data('datepicker');

        elems.$input.click(() => {
            datePickerObject.show();
        })
    }
}