import "@babel/polyfill";
import 'slick-carousel';
import 'air-datepicker';
import 'jquery-mask-plugin/src/jquery.mask';
import './_prototypes';

import Tools from './_tools';
import RatingStars from "../../blocks/project/b-rating-stars/b-rating-stars";
import worksSliderInit from "../../blocks/project/b-works-slider/b-works-slider";
import faqInit from "../../blocks/project/b-faq/b-faq";
import Header from "../../blocks/project/b-header/b-header";
import initDatePicker from "../../blocks/project/b-input-date/b-input-date";
import InputFiles from "../../blocks/project/b-input-files/b-input-files";
import FormMain from "../../blocks/project/b-form-main/b-form-main";
import popupsInit from '../../blocks/project/b-popup/b-popup'

$(document).ready(() => {
    Tools.initSelect2();
    RatingStars.init();
    worksSliderInit();
    faqInit();
    Header.init();
    initDatePicker();
    InputFiles.init();
    FormMain.init();
    popupsInit();
});
