import 'select2/dist/js/select2.full.min';

export default class Tools {
    static initSelect2() {
        function formatStateSocials (state) {
            if (!state.id) {
                return state.text;
            }
            const baseUrl = "../assets/images/socials";
            const $state = $(
                '<div class="b-input-select__social-holder"><div class="b-input-select__icon-holder"><img src="' + baseUrl + '/' + state.element.value.toLowerCase() + '.svg" class="b-input-select__social-icon" /></div> ' + state.text + '</div>'
            );
            return $state;
        };

        function switchTemplateResultThemes($selectElem) {
            const templateResult = $selectElem.data('options').templateResult;

            switch (templateResult) {
                case 'socials':
                    return formatStateSocials;
                default:
                    return;
            }
        }

        $('.js-select').each(function () {
            const $select = $(this);
            const params = $select.data('params');
            const inlineParams = $select.data('inline-params');
            $(this).select2({
                templateResult: switchTemplateResultThemes($select),
                ...params,
                ...inlineParams
            });

            $('.select2-selection').keydown((e) => {
                if(e.key === 'Enter'){
                    $select.select2('close')
                }
            })
        });


    }

    static initYouTubeApi(){
        const tag = document.createElement('script');

        tag.src = "https://www.youtube.com/iframe_api";
        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }
}