export default class Header {
    static get selectors() {
        return {
            main: '.b-header',
            burger: '.b-header__burger'
        }
    }

    static get classes() {
        return {
            mobileMenuActive: 'b-header_mobile-menu_active'
        }
    }

    static init() {
        document.querySelectorAll(Header.selectors.main).forEach(elem => {
            new Header(elem);
        })
    }

    constructor(elem) {
        this.params = {
            selectors: Header.selectors,
            classes: Header.classes
        };

        this.elems = {
            main: elem,
            burgers: elem.querySelectorAll(`:scope ${this.params.selectors.burger}`)
        };

        this.handleMobileMenuOpen()

    }

    handleMobileMenuOpen() {
        this.elems.burgers.handleEvent('click', () => {
            this.toggleMobileMenu()
        })
    }

    toggleMobileMenu() {
        this.elems.main.classList.toggle(this.params.classes.mobileMenuActive)
    }

}