const ICON_CLASS_PREFIX = 'b-input-text_icon';

export default class FormMain {
    static get selectors() {
        return {
            main: '.b-form-main',
            socialConnectSelect: '.js-connect-choose .js-select',
            socialConnectInput: '.b-form-main__input_social-connect',
            socialConnectBlock: '.js-social-connect-block',
            socialConnectInputElem: `.js-social-connect-block .b-input-text__input`
        }
    }

    static get classes() {
        return {
            hide: 'u-hide',
        }
    }

    static get placeholders() {
        return {
            tg: 'Ваш номер Telegram',
            em: 'Ваш email адрес',
            fb: 'Ваш адрес Facebook',
            vb: 'Ваш номер Viber',
            wa: 'Ваш номер WhatsApp',
            vk: 'Ваш адрес Вконтакте'
        }
    }

    static init() {
        $(FormMain.selectors.main).each((i, elem) => {
            const $elem = $(elem);
            if($elem.data('block-api')) return;

            new FormMain($elem);
        })
    }

    constructor($elem) {
        this.params = {
            selectors: FormMain.selectors,
            classes: FormMain.classes
        };

        this.elems = {
            $main: $elem,
            $socialConnectSelect: $elem.find(this.params.selectors.socialConnectSelect),
            $socialConnectInput: $elem.find(this.params.selectors.socialConnectInput),
            $socialConnectBlock: $elem.find(this.params.selectors.socialConnectBlock),
            $socialConnectInputElem: $elem.find(this.params.selectors.socialConnectInputElem)
        };

        this.handleConnectionChange();
        this.initBlockApi();
    }

    initBlockApi() {
        this.elems.$main.data('block-api', this);
    }

    handleConnectionChange() {
        this.elems.$socialConnectSelect.on('change', (event) => {
            this.elems.$socialConnectInputElem.val('');
            const value = $(event.currentTarget).val();

            this.toggleIconClass(this.elems.$socialConnectBlock, value);
            this.elems.$socialConnectInputElem.attr('placeholder', FormMain.placeholders[value]);

            if(value){
                this.elems.$socialConnectInput.removeClass(this.params.classes.hide);
            } else {
                this.elems.$socialConnectInput.addClass(this.params.classes.hide)
            }

            if(['tg','vb','wa'].indexOf(value) !== -1) {
                this.elems.$socialConnectInputElem.mask("+7(999) 999-99-99")
            } else {
                this.elems.$socialConnectInputElem.unmask();
            }
        })
    }

    toggleIconClass($elem, classPostfix) {
        const socialConnectBlockClassList = $elem.attr('class').split(/\s+/);
        let iconClass = '';
        socialConnectBlockClassList.forEach((classItem) => {
            if(iconClass.indexOf(ICON_CLASS_PREFIX, 0)){
                iconClass = classItem;
            }
        });
        $elem.removeClass(iconClass);
        $elem.addClass(`${ICON_CLASS_PREFIX}_${classPostfix}`);
    }

}