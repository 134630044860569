
export default class RatingStars{
    static get selectors(){
        return{
            main: '.b-rating-stars',
            starItem: '.b-rating-stars__star-holder',
            starImgFilled: '.b-rating-stars__star-img_filled'
        }
    }

    static get classes(){
        return{
            starImgActive: 'b-rating-stars__star-img_active'
        }
    }

    static get dataSelectors(){
        return{
            rating: 'rating',
            blockApi: 'block-api'
        }
    }

    static init($context = $(document), options){
        $(RatingStars.selectors.main, $context).each((index, elem) => {
            const $elem = $(elem);

            if(!$elem.hasClass('js-init')) return;
            if($elem.data(RatingStars.dataSelectors.blockApi)) return;

            new RatingStars($elem, options);
        })
    }

    constructor($elem, options){
        this.params = $.extend({
            selectors: RatingStars.selectors,
        }, options);

        this.elems = {
            $main: $elem,
            $starItem: $elem.find(this.params.selectors.starItem),
            $starImgFilled: $elem.find(this.params.selectors.starImgFilled)
        };

        this.state = {
            rating: this.elems.$main.data(RatingStars.dataSelectors.rating) || 0
        };

        this.renderRating();

        this.initBlockApi();
    }

    initBlockApi(){
        this.elems.$main.data(RatingStars.dataSelectors.blockApi, this);
    }

    renderRating(rating = this.state.rating){
        this.elems.$starItem.removeClass(RatingStars.classes.starImgActive);
        this.elems.$starItem.filter( index => index < rating )
            .find(this.elems.$starImgFilled)
            .addClass(RatingStars.classes.starImgActive)
    }

}